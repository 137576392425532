@charset "UTF-8";

.ask-bg-primary {
  background-color: #3db36b !important;
}

.navbar {
  font-family: Arimo, sans-serif !important;
  letter-spacing: .120rem !important;
  font-weight: 750 !important;
  font-size: .75rem !important;
  line-height: 1.0 !important;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  margin-right: 10px; /* Adjust the space between the logo and the brand name if needed */
}

.navbar .navbar-text {
  font-size: .70rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-light {
  background-color: transparent !important;
}

.list-group {
  border-color: transparent !important;
  border-width: 0px !important;
}

.list-group-item {
  background-color: transparent !important;
  border: none !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.navbar {
  --bs-navbar-padding-y: 0.0rem !important;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link,
  .navbar-expand-lg .navbar-text {
    padding: 1.70rem 0;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

/* General styles for the header */
.header-fixed-xl {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-main {
  border-bottom: 1px solid #ddd; /* Optional: to separate the header from the content */
}

.navbar-nav .nav-item .nav-link {
  position: relative; /* Required for positioning the underline */
  transition: color 0.3s; /* Smooth color transition */
}

/* Hover effect: change text color */
.navbar-nav .nav-item .nav-link:hover {
  color: #3db36b; /* Change this to your desired hover color */
}

/* Hover effect: underline */
.navbar-nav .nav-item .nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px; /* Thickness of the underline */
  bottom: 0;
  left: 0;
  background-color: #3db36b; /* Change this to your desired underline color */
  transform-origin: bottom;
  transition: transform 0.3s ease-out;
}

.navbar-nav .nav-item .nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Active link effect: change text color and underline */
.navbar-nav .nav-item .nav-link.active {
  color: #3db36b; /* Change this to your desired active color */
}

.navbar-nav .nav-item .nav-link.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Close button styles */
.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.0rem;
  cursor: pointer;
}
