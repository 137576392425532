/* Ensure the carousel takes the full height of the viewport */
.carousel,
.carousel-item-fullscreen {
  height: 84vh; /* 80% of the viewport height */
}

/* Ensure the image covers the entire carousel item */
.carousel-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio, cover entire container */
}

/* Adjust the caption styles if needed */
.carousel-caption {
  bottom: 20px; /* Adjust the position of the caption */
}
