.btn-ask {
	background-color: #308d54 !important;
	border-color: #2d834f !important;
	color: #fff !important;
	letter-spacing: .125rem !important;
	text-transform: uppercase !important;
	font-weight: 700 !important;


	padding: 1.25rem 2.65rem !important;
	font-size: .75rem !important;
	line-height: 1.5 !important;
	border-radius: 0 !important;
}

.btn-ask-md {
	background-color: #308d54 !important;
	border-color: #2d834f !important;
	color: #fff !important;
	letter-spacing: .110rem !important;
	text-transform: uppercase !important;
	font-weight: 700 !important;


	padding: 1.12rem 2.15rem !important;
	font-size: .65rem !important;
	line-height: 1.0 !important;
	border-radius: 0 !important;
}
.btn-ask:hover {
	background-color: #1E8449 !important;
	color: #fff !important;
}

.btn-ask-md:hover {
	background-color: #1E8449 !important;
	color: #fff !important;
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
}
.custom-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 140px; /* Reduced height by 20 percent */
  border: none;
  border-radius: 0;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.custom-card .image-col {
  flex: 0 0 40%;
  max-width: 40%;
  overflow: hidden;
  height: 100%;
}

.custom-card .image-container {
  width: 100%;
  height: 100%; /* Ensure the container takes the full height of the card */
  display: flex;
  align-items: center;
}

.custom-card .custom-card-image {
  width: 100%;
  height: auto; /* Keep aspect ratio */
  object-fit: cover;
  border-radius: 0;
}

.custom-card .text-col {
  flex: 0 0 60%;
  max-width: 60%;
  font-size: 0.875rem; /* Adjust text size */
  text-align: justify;
}

.custom-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; /* Ensure the card body takes the full height of the card */
}

.custom-card .card-title {
  font-weight: bold; /* Make title bolder */
}

.custom-card-container {
  margin-top: 4rem;
}
