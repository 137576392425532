.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.whatsapp-button:hover {
  background-color: #128C7E;
}

.whatsapp-button svg {
  margin-right: 10px;
}
