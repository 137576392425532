.footer {
  background-color: transparent; /* Transparent background color */
  color: #343a40; /* Dark text color */
  margin-top: 30px; /* Top margin */
}

.footer-brand {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  margin-right: 20px; /* Added right margin for more spacing */
}

.footer .material-icons {
  font-size: 1.5rem;
}

.footer .list-inline-item a {
  color: inherit;
  text-decoration: none;
}

.footer .list-inline-item a:hover {
  text-decoration: underline;
}

.footer-icon {
  font-size: 2rem;
}
