.section {
	padding: 5rem 0;
}

.about-font {
	font-family: Roboto, sans-serif;
}

.fs-1-ask {
	font-size: 0.75rem !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.h1-ask {
	font-size: 3.125rem;
}

.about-container {
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	/* Hide horizontal scrollbar */
	padding: 1rem;
}

.about-card {
	min-width: 250px;
	max-width: 250px;
	min-height: 220px;
	
	flex: 0 0 auto;
	margin: 10px; 
	border: none;
	box-shadow: 0 0 2px 2px rgba(0.1, 0.1, 0.1, 0.1);
	transition: transform 0.2s ease-in-out;
	display: block;
}

/*.service-card.active {
	display: block;
}
*/
.about-card:hover {
	transform: scale(1.05);
}

.about-card .card-title {
	font-size: 1.25rem;
	font-weight: bold;
}

.about-card .card-text {
	font-size: 0.875rem;
	text-align: justify;
}

.about-row {
	position: relative;
}

.nav-buttons {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: space-between;
	width: 110%;
	margin-left: -3rem;
}


.prev-button,
.next-button {
  padding: 5px !important;  /* Adjust padding */
  font-size: 1.5rem; /* Adjust font size */
  cursor: pointer;
  border: none !important;  /* Remove border */
  background-color: transparent !important; /* Set background to transparent */
  color: black !important; /* Set color to black */
}

.prev-button:hover,
.next-button:hover {
  color: #333; /* Change color on hover */
}

.bi-arrow-left
{
    background-color:#3db36b;
}
.bi-arrow-right {
    background-color:#3db36b;
}
.lead {
	font-size: 1.25rem;
	font-weight: 300;
}

.text-muted {
	color: #6c757d !important;
}

.text-center {
	text-align: center !important;
}