.testimonials-section {
	padding: 2rem 0;
}

.testimonials-row {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none;
	/* Firefox */
}

.testimonials-row::-webkit-scrollbar {
	display: none;
	/* Hide scrollbar for Chrome, Safari, and Opera */
}

.testimonial-card {
	min-width: 300px;
	scroll-snap-align: start;
	border: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-card .card-title {
	font-size: 1.25rem;
	font-weight: bold;
}

.testimonial-card .card-subtitle {
	font-size: 1rem;
	color: #6c757d;
}

.testimonial-card .card-text {
	font-size: 0.875rem;
}

.scroll-buttons {
	position: relative;
	margin-top: -7%;
	margin-left: -3%;
	margin-right: -3%;
}


.scroll-buttons button {
	background-color: #308d54;
	color: #fff;
	border: none;
	border-radius: 0%;
	width: 30px;
	/* Width is twice the height */
	height: 60px;
	/* Height is half the width */
	font-size: 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.scroll-buttons button:hover {
	background-color: #1E8449;
}