.inquiry-modal .img-fluid {
  max-width: 100%;
  height: 75%; /* Set image height to 75% */
}

.inquiry-modal .offer-text {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
  text-align: center;
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 4px;
}

/* General Modal Styling */
.inquiry-modal .modal-body {
  padding: 2rem;
}

.inquiry-modal h4 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.inquiry-modal .form-group {
  margin-bottom: 1rem;
}

.inquiry-modal .form-control {
  border-radius: 4px; /* Small radius for cleaner look */
}

.inquiry-modal .btn-ask-md {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.inquiry-modal .btn-ask-md:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Adjustments for Property Type Section */
.inquiry-modal .property-type-section {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.inquiry-modal .property-type-option {
  padding: 0.3rem 0.55rem;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: small;
}

.inquiry-modal .property-type-option:hover,
.inquiry-modal .property-type-option.selected {
  background-color: #007bff;
  border-color: #007bff;
  color: #ffffff;
}
.inquiry-modal .modal-header .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5rem;
  color: #000;
}

.inquiry-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}