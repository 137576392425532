@media (min-width: 1200px) {
    .sds-block {
        padding: 3.125rem 0;
    }
}

.sds-block {
    display: -ms-flexbox;
    display: flex;
    position: relative;
}
.sds-block-background.sds-block-background-right {
    right: 0;
}
.sds-block-background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 70%;
    z-index: 5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sds-block-foreground.sds-block-foreground-left {
    margin-right: auto;
}
.sds-block-foreground {
    position: relative;
    z-index: 10;
    background: #fff;
    width: 45%;
    padding: 3.75rem 2.125rem 3.75rem 4.375rem;
}
@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
}

@media (min-width: 1200px) {
    .mt-xl-20, .my-xl-20 {
        margin-top: 3.125rem !important;
    }
}
.mt-14, .my-14 {
    margin-top: 1.875rem !important;
}
.mb-12, .my-12 {
    margin-bottom: 1.625rem !important;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.mb-6, .my-6 {
    margin-bottom: .875rem !important;
}
@media (min-width: 1200px) {
    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.material-icons {
    vertical-align: middle;
}
.text-primary {
    color: #3db36b !important;
}
.mr-1, .mx-1 {
    margin-right: .25rem !important;
}
@media (min-width: 576px) {
    .d-sm-inline-block {
        display: inline-block !important;
    }
}

.icon-background {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 21px; /* Adjust size as needed */
  height: 21px; /* Adjust size as needed */
  background-color: #308d54; /* Background color */
  border-radius: 50%; /* Make it rounded */
  color: white; /* Icon color */
  margin-right: 8px; /* Space between icon and text */
}

.checkmark-icon {
  width: 11px; /* Adjust size as needed */
  height: 11px; /* Adjust size as needed */
}
