/* Work.css */
.section {
  padding: 60px 0;
}

.card-square {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* This makes the card square */
  overflow: hidden;
}

.card-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: background-color 0.3s;
}

.card-bg-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.6);
  opacity: 0; /* Start with the hover effect hidden */
  transition: opacity 0.3s;
}

.card-square:hover .card-bg-hover {
  opacity: 1; /* Only show the hover effect on hover */
}

.card-body-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.card-square:hover .card-body-hover {
  opacity: 1;
}

.card-link-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.zoom-icon {
  font-size: 3rem;
}
