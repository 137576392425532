@charset "UTF-8";

.cucol .form-group {
	    margin-bottom: 1.25rem;
	    text-align: left;
}

.cucol .form-label {
    font-family: Arimo, sans-serif;
    letter-spacing: .125rem;
    text-transform: uppercase;
    font-size: .75rem;
    color: #9b9b9b;
}

.cucol .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (min-width: 992px) {
    .pb-lg-20, .py-lg-20 {
        padding-bottom: 3.125rem !important;
    }
}
@media (min-width: 992px) {
    .pt-lg-20, .py-lg-20 {
        padding-top: 3.125rem !important;
    }
}
.pb-19, .py-19 {
    padding-bottom: 2.5rem !important;
}
.pt-19, .py-19 {
    padding-top: 2.5rem !important;
}

.row.vertical-divider-light {
    --vertical-divider-color: #EFEFEF;
}
.row[class*=vertical-divider-] {
    overflow-x: hidden;
    position: relative;
}

.pb-17, .py-17 {
    padding-bottom: 2.25rem !important;
}
.pt-17, .py-17 {
    padding-top: 2.25rem !important;
}
.pl-10, .px-10 {
    padding-left: 1.375rem !important;
}
.pr-10, .px-10 {
    padding-right: 1.375rem !important;
}

.card-icon {
    color: #adb5bd;
    line-height: 1;
}
.lh-1 {
    line-height: .75rem !important;
}
.text-muted {
    color: #9b9b9b !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.mb-5, .my-5 {
    margin-bottom: .75rem !important;
}
.mt-5, .my-5 {
    margin-top: .75rem !important;
}